import { useTheme } from '@chakra-ui/react';
import Select from 'react-select';

import InputField from '@/components/Inputs/InputField';

const CustomSelect = ({
    onChange,
    value,
    options,
    isSearchable = false,
    isClearable,
    label,
    placeholder,
    name,
    ...props
}) => {
    const theme = useTheme();

    return (
        <InputField label={label} name={name} type="text" {...props}>
            <Select
                inputId={name}
                instanceId={name}
                isClearable={isClearable}
                isSearchable={isSearchable}
                onChange={onChange}
                options={options}
                placeholder={placeholder}
                styles={{
                    indicatorSeparator: (provided) => ({
                        ...provided,
                        width: 0
                    }),
                    placeholder: (provided) => {
                        return {
                            ...provided,
                            fontWeight: 'bold',
                            color: theme.colors.gray[400]
                        };
                    },
                    control: (provided) => {
                        return {
                            ...provided,
                            borderRadius: theme.radii.lg,
                            minHeight: props.inputHeight
                        };
                    },
                    singleValue: (provided) => {
                        return {
                            ...provided,
                            color: theme.colors.tooddoc[600],
                            fontWeight: 600
                        };
                    }
                }}
                value={value}
                {...props}
            />
        </InputField>
    );
};

export default CustomSelect;
