import { VStack } from '@chakra-ui/react';

const Form = ({ children, ...props }) => {
    return (
        <VStack as="form" align="baseline" {...props} width="100%">
            {children}
        </VStack>
    );
};

export default Form;
