import {
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Icon,
    Input,
    InputGroup,
    InputLeftAddon,
    InputLeftElement,
    Textarea
} from '@chakra-ui/react';

const InputField = ({
    name,
    label,
    type,
    placeholder,
    onChange,
    children,
    helperText,
    error,
    isRequired,
    autoComplete,
    value,
    inputRef,
    defaultValue,
    isTextArea,
    addon,
    icon,
    ...props
}) => {
    return (
        <FormControl isRequired={isRequired} isInvalid={error} marginLeft="0" {...props}>
            {label && (
                <FormLabel
                    as="label"
                    color="gray.600"
                    fontSize="xs"
                    fontWeight="bold"
                    htmlFor={name}>
                    {label}
                </FormLabel>
            )}
            {children ? (
                children
            ) : (
                <InputGroup alignItems="center">
                    {addon && <InputLeftAddon height="3rem">{addon}</InputLeftAddon>}
                    {icon && (
                        <InputLeftElement top="inherit" pointerEvents="none">
                            <Icon as={icon} size={20} color="gray.500" />
                        </InputLeftElement>
                    )}
                    {!isTextArea && (
                        <Input
                            aria-describedby={name}
                            autoComplete={autoComplete}
                            borderRadius="lg"
                            height="3rem"
                            id={name}
                            inputRef={inputRef}
                            onChange={onChange}
                            placeholder={placeholder}
                            type={type}
                            defaultValue={defaultValue}
                            value={value}
                            {...props.input}
                        />
                    )}
                    {isTextArea && (
                        <Textarea
                            aria-describedby={name}
                            borderRadius="lg"
                            id={name}
                            onChange={onChange}
                            placeholder={placeholder}
                            value={value}
                            {...props.input}
                        />
                    )}
                </InputGroup>
            )}
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
            {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
        </FormControl>
    );
};

export default InputField;

export const CustomInputLabel = ({ children, htmlFor }) => (
    <FormLabel color="gray.600" fontSize="xs" fontWeight="bold" htmlFor={htmlFor}>
        {children}
    </FormLabel>
);
